*{
    font-family: "Imprima", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.container{
    justify-content: center;
    align-items: center;
}

.nameprefeitura{
    font-family: "Baloo Thambi 2", system-ui;
    color: black;
    font-size: 25px;
    font-weight: bold;
    max-width: 300px;
    display: flex;
}

.containerImg{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 40px;
}

.cardContainer{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;  
    margin-bottom: 30px;
}

.img{
    width: 25%;  
    
}

.iconCaixa{
    width: 35px;
    width: 35px;
}

@media (max-width:638px){
    .img{
        width: 70%;    
    }
    .nameprefeitura{
        font-size: 18px;
        
    }
}