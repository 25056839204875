.appBar{
    width: 100%;
    display: flex;
    align-items: center;
    background-color:rgba(0, 191, 252, 0.17);
    height: 56px;

}

.titulo{
    font-weight: 700;
    font-size: 25px;
    margin-top: 5px;
    }