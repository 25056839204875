
.containerButton{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 30px 0;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 70px;

}
.ButtonApiRemover, .ButtonApiEnviar{

    border: none;
    border-radius: 10px;
    width: 110px;
    height: 35px;
    text-align: center;
    color: white; 
    
}

.ButtonApiRemover:hover
{
    background-color: rgb(200, 50, 50);
}

.ButtonApiRemover{

    background-color: rgb(235, 64, 64);
    font-weight: bold;
    font-size: 18px;
    
}
.ButtonApiEnviar:hover
{
    background-color: rgb(90, 195, 54);
}

.ButtonApiEnviar{
    
    border: 1px solid #75FF45;
    margin-right: 10px;
    background-color: #75FF45;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.Spinner{
    position: absolute;
    top: 25%;
    left: 45%;
}