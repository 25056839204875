

.subtituloContainer{
    padding: 20px;
    text-align: center;

}
.subtitulo{
    font-size: 18px;
    font-weight: 700;
}

.filter{
    min-width: 359px;
    height: 50px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .btnFilter{
    border-width: 1px;
    border-color: gray;
    padding: 3px 15px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 15px;
    background-color:rgba(0, 191, 252, 0.17);
    margin-right: 10px;
  }

.inputFilter{
    width: 150px;
    height: 30px;
    border: 1px solid grey ;
    text-align: center;
    border-radius: 5px;
    margin-left: 10px;
  
  }

.campoPedidosContainer{
    display: block;
}

.campoPedidosCabecalho{
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.pdfButton{
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border-width: 1px;
    border-color: red;
    
}
.pdficon{
    height: 35px;
    width: 35px;
}
.btnText{
    margin-left: 10px;
    font-size: 15px;
    font-weight: 700;
    color: red;
    
}

.pedidosTituloContainer{
    text-align: center;
    padding: 10px;
    background-color:rgba(0, 191, 252, 0.17);
    margin-bottom: 20px;
}

.pedidosTitulo{
    font-size: 22px;
    font-weight: bold;
}

.pedidosContainer{
    display: block;
    justify-content: center;
}
.pedidosDiv{
    margin-left: 15px;
    margin-top: 10px;
}
.pedidosSubtitulo{
    
    font-size: 18px;
    font-weight: 700;
}
.pedidosTexto{
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
}

.space{
    padding: 10px ;
    font-weight: 700;
    
}