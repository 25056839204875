
.container{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.containerComponents{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  
}
.containerList{
  margin-bottom: 20px;
}

/* Filtro de data */

.filter{
  min-width: 359px;
  height: 50px;
  background-color:rgba(0, 191, 252, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  border-radius: 5px;
}
.textfilter{
  font-weight: 700;
  font-size: 15px;
}
.inputFilter{
  width: 150px;
  height: 30px;
  border: 1px solid grey ;
  text-align: center;
  border-radius: 5px;
  margin-left: 10px;

}


.calculationFactor{
  width: 120px;
}


.Spinner{
  position: absolute;
  top: 25%;
  left: 45%;
}
