.containerPrincipal{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height:  60vh;
}
.containerList{
  
  background-color:rgba(0, 191, 252, 0.17);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 0;
  min-width: 350px;
  
}
.containerData{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* nome do produto / data /icone da data*/

.name {
  text-align: center;
  min-width: 350px;
  height: 20px;
  font-size: 15px;
  font-weight: 700;
  background-color:rgba(0, 191, 252, 0.67);
  padding: 12px 0; 
  align-items: center;
  justify-content: center;
  display: flex;
  
}
.containerData{
  background-color:rgba(0, 191, 252, 0.34);
  height: 50px;
  margin-top: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /*padding: 5px 0;*/
  flex-direction: row;
  display: flex;
}
.icon{
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  background: white;
  margin-right: 5px;
  
}

.data{
  font-size: 20px;
  font-weight: 700;
  align-items: center;
  display: flex;
}

.confOrder{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  margin-left: 180px;
}

.blue {
  background-color: white;
}

.red {
  background-color: greenyellow;
}

/*familia*/
.familyTitle {
  margin-left: 5px;
  font-size: 20px;
  font-weight: 700;
}

.cursodiv{
  display: flex;
  justify-content: center;
}

.cursoTitle{
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

/*Lista*/
.list{
  display: flex;
  margin: 10px;
  max-width: 340px;
  
}
.infocolumn{
  display: flex;
  flex-direction: column;
}
.containerBtn{
 
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 20px;
  margin-right: 5px;

}

/* nome do produto / perecivel*/
.text{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  word-wrap: break-word; /* Permite a quebra de linha */
  overflow-wrap: break-word;
}
.perishable{

  width: 200px;
  
}
.nameproduct{
  
  max-width: 150px;
}  
/* valores de quantidade e unidade*/
.values{
  display: flex;
  flex-direction: column;

}
.qtd, .un{
  
  width: 130px;
}

.spaces{
  
  margin: 0px;
  padding: 0px;
  background-color: grey;
  margin-bottom: 3px;
  height: 3px;
  border-radius: 5px;
}

.greenButton {
  margin: 0;
  background: linear-gradient(135deg, rgba(13, 255, 13, 1) 0%, rgb(8, 140, 8) 100%);
  color: black;
  border: solid;
  border-radius: 6px;
  border-width: 1.5px;
  cursor: pointer;
}

.defaultButton{

  margin: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(200, 200, 200, 1) 100%);
  color: black;
  border: solid;
  border-radius: 6px;
  border-width: 1.5px;
  cursor: pointer;
}



