.containerProduto {
    display: flex;
  }

  .nomeProduto {
    font-weight: bold;
    font-size: 20px;
    color: #3D3D3D;
    max-width: 200px;
  }

  .containerInput{
    margin-left: auto;
    
  }

  .inputTitulo{
    font-size: 12px;
    margin-right: 5px;
  }

  .input {
    font-size: 12px;
    width: 50px;
    height: 25px;
    margin-left: auto;
    border: 1px solid #3D3D3D;
    border-radius: 3px;
    text-align: center;
    text-align: center;
  
    
  }

  .containerDetalhes {
    display: flex;
    margin: 5px 2px 0 2px;
  }

  .perecivel {
    font-size: 12px;
    color: #3D3D3D;
  }
  
  .unidade {
    align-items: flex-end;
    margin-left: auto;
    font-size: 12px;
    color: #3D3D3D;
  }