.container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.containerComponents {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.errormsg {
  
    margin-top: 35px;
    text-align: center;
    font-size: 20px;
}

.footer {

    position: absolute;
    top: 83%;
    width: 100%;
    margin-bottom: 0;
}