*{
  font-family: "Imprima", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.container{
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
}
/* APP bar*/
.inputFilter {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 10px;
  margin-right: 5px;
}
.containerAppBar{
  
  width: 100%;
  display: flex;
  align-items: center;
  background-color:rgba(0, 191, 252, 0.17);
  height: 56px;

}
.ButtonReturn{
  background: none;
  border: none;
  padding: 7px 2px;
}

.titulo{

  font-weight: 700;
  font-size: 25px;
  margin: 0 2px 0 3px;

}
.alighlist{
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}



.containerList{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:rgba(0, 191, 252, 0.17);
  min-width: 350px;
  padding-top: 5px;
  margin-bottom: 50px;
}

/* Filtro */
.containerFilter {
  min-width: 350px;
  height: 60px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color:rgba(0, 191, 252, 0.50);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.buttonClear, .buttonSave{
 
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px ;
  padding: 2px;
  background-color: white;

}
.buttonSave{
  margin-right: 5px;
  
}

.containerItem {
  min-width: 340px;
  min-height: 40px;
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  padding: 10px;
  background-color:rgba(0, 191, 252, 0.50);
  
}



.errormsg{
  font-size: 25px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  
  
}
.Spinner{
    position: absolute;
    top: 25%;
    left: 45%;
}

