.containerUsername{
    display: flex;
    flex-direction: column;  
    background-color:rgba(0, 191, 252, 0.17);
    padding-left: 15px;

}

.username{
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
}
.nomebanco{
    font-size: 16px;
    margin-left: 10px;
}