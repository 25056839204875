.card{
    /*Card*/
    width: 300px; 
    /*background-color:rgba(0, 191, 252, 0.17);*/
    /*background: linear-gradient(to right, #00FBFF 30%, rgba(0, 191, 252, 0.17) 70%);*/
    margin: 10px;
    border-radius: 15px;
    padding: 5px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
    position: relative;
}

.contaier{
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribui o espaço entre os elementos */
    position: relative;
}

.containerIcon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid white;
    background: white;
}
.icon {
    margin-top: 8px;
    margin-left: 8px;
    width: 32px;
    height: 32px;
}
.title{
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
    flex-grow: 1;
}
.date{
    margin-right:2px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    width: 28px;
    height: 28px;
    color:white;
    border: 3px;
    
}
.text{
    font-weight: bold;
    font-size: 12px;
    color: grey;
    width: 85%;
    
}
.containertext{

    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    justify-content: flex-end;
    
}

/*justify-content: center; alinha o conteudo horizontalmente */
.link{

    text-decoration: none;
    display:flex;
    width: 90px;
    height: 25px;
    border-radius: 15px;
    background-color: #75FF45;
    color: white;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

@media (max-width:668px){

    .card{
        /*Card*/
        width: 70%;
    }
    

}